import React from 'react'
import { graphql } from 'gatsby'
import Bio from '@components/bio'
import Layout from '@components/layout'
import SEO from '@components/seo'
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import Parallax from '@components/Parallax/Parallax'
import postsStyle from '@assets/jss/posts.js'
import Courses from '@components/courses'
const useStyles = makeStyles({
  ...styles,
  ...postsStyle,
})

// function generate(list, element) {
//   return list.map((value, index) =>{
//     console.log(value)
//     return React.cloneElement(element, {
//       key: index,
//       primary: value.primary,
//       secondary: value.secondary
//     }) 
//   }
//   )
// }
const CoursesPage = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const classes = useStyles()
  // const ListElement = ({ primary, secondary }) => {
  //   return <ListItem>
  //   <ListItemAvatar>
  //     <Avatar>
  //       <FolderIcon />
  //     </Avatar>
  //   </ListItemAvatar>
  //   <ListItemText
  //     primary={primary}
  //     secondary={secondary}
  //   />
  // </ListItem>
  //   }
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="All courses" />
      <Parallax filter image={require('@assets/img/landing-bg.jpg')} tiny>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <Bio title="课程介绍" style={{ position: 'static' }} />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <br />

            <Courses posts={posts} />
        </div>
      </div>
    </Layout>
  )
}

export default CoursesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: {
        frontmatter: { type: { eq: "courses" }, visible: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            titleEnglish
            type
            thumbnail {
              childImageSharp {
                fixed(width: 300, height: 300, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
