import React from 'react'
import Img from 'gatsby-image'
import clsx from 'clsx'
import Link from '@components/Link'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import postsStyle from '@assets/jss/posts.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'
const useStyles = makeStyles({
  ...styles,
  ...postsStyle,
})
// const coursesList = [{
//   primary: "中国舞+中国舞考级",
//   secondary: ""
// },{
//   primary: "芭蕾舞+芭蕾舞考级",
//   secondary: ""
// },{
//   primary: "街舞",
//   secondary: ""
// },{
//   primary: "嘻哈舞",
//   secondary: "Hiphop"
// },{
//   primary: "爵士舞",
//   secondary: "Jazz"
// },{
//   primary: "成人中国舞班",
//   secondary: ""
// },{
//   primary: "成人健身舞班",
//   secondary: ""
// },{
//   primary: "成人芭蕾舞班",
//   secondary: ""
// },{
//   primary: "瑜伽",
//   secondary: ""
// },{
//   primary: "少儿声乐",
//   secondary: ""
// }]
const Courses = props => {
  const { posts } = props
  const classes = useStyles()  
  const isDesktop = useMediaQuery('(min-width:960px)')
  return (
    <Grid container spacing={ isDesktop ? 3 : 0 }>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        const titleEnglish = node.frontmatter.titleEnglish || ""
        const FeaturedImage = () => {
          if (node.frontmatter.thumbnail) {
            return (
              <Img
                className={classes.media}
                fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
              />
            )
          }
          return (
            <div
              style={{
                width: '100%',
                height: '300px',
              }}
            ></div>
          )
        }
        return (
          <Grid item xs={6} sm={6} md={4} lg={3} key={node.id}>
            <Card
              variant="outlined"
              key={node.fields.slug}
              style={{ marginBottom: "0" }}
            >
              <Link
                to={`/${node.frontmatter.type}${node.fields.slug}`}
                underline="none"
              >
                <CardActionArea>
                  <div
                    className={clsx(classes.imageWrap, classes.imageWrapDark)}
                    style={{ minWidth: '100%', height: '100%', backgroundColor: '#fff3fe' }}
                  >
                    <FeaturedImage />
                    <Typography
                      className={classes.bottomText}
                      variant="h6"
                      component="h3"
                    >
                      <strong style={{ color: "#652233" }}>{title}</strong>
                      <br />
                      <span style={{ color: "#ffeedb" }}>{titleEnglish}</span>
                    </Typography>
                  </div>
                  <CardContent className={classes.content} style={{ height: "1px", padding: "0" }}>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Courses
